.fadeIn {
	opacity: 0;
	animation: fadeIn 0.7s forwards;
}

.fadeInUp {
	opacity: 0;
	animation: fadeInUp 1s forwards;
}

.logo-text {
	font-size: 60px;
	font-family: "Lobster";
}

.header .logo-text:hover,
.count-text:hover {
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.logo-text {
		font-size: 36px;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
